.container {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background-color: var(--grey-darker);
  border-radius: var(--border-radius);
  padding: var(--spacing-sm);
  transition: all 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    filter: brightness(1.2);
  }

  .cardImage {
    display: inline-block;
    width: 100%;

    &.notOwned {
      opacity: 0.5;
      filter: grayscale(70%);
    }
  }

  .cardPlus {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .cardPlusBox {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      z-index: 1;
      background-color: var(--grey-darker);
    }
  }

  .cardInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .buttons {
      width: 100%;
      margin-top: var(--spacing-sm);

      .status {
        margin-bottom: var(--spacing-2xs) !important;
      }
      .action {
        .buttonContent {
          display: flex;
          justify-content: space-between;
          width: 100% !important;
        }
        .icon {
          margin-right: 4px;
        }
      }
    }
  }
}

.statusInfo {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: var(--spacing-xs) 0 0 0;
  width: 100%;
  .status {
    margin-bottom: var(--spacing-2xs) !important;
  }
}

.inImmutable {
  border: 1px solid var(--immutable);
  padding: var(--spacing-2xs) var(--spacing-xs);
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    fill: var(--immutable) !important;
    margin-right: 4px;
  }
}

.inWallet {
  border: 1px solid var(--eth);
  padding: var(--spacing-2xs) var(--spacing-xs);
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    fill: var(--eth) !important;
    margin-right: 4px;
  }
}

.inProcessing {
  background-color: rgba(255, 255, 255, 0.05);
  padding: var(--spacing-2xs) var(--spacing-xs);
  border-radius: 3px;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    margin-right: 4px;
  }
}

.icon {
  fill: var(--immutable) !important;
  margin-right: 4px;
}

.action {
  margin-top: var(--spacing-2xs);
  height: 30px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  overflow: visible;
  z-index: 1;
  &:hover {
    .hoverText {
      opacity: 1;
      transform: translateY(0px);
      visibility: visible;
    }
  }
  .hoverText {
    margin-left: var(--spacing-2xs);
    transition: var(--transition-short-ease);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: 30px;
    background: black;
    border-radius: 4px;
    padding: var(--spacing-xs);
    white-space: nowrap;
    z-index: 10;
    transform: translateY(-5px);

    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      transform: rotate(45deg);
      position: absolute;
      bottom: -3px;
      left: 50%;
      margin-left: -5px;
      background: black;
    }
  }
}
