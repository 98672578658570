.confirmRemovePackListings {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .msg {
    line-height: var(--font-xl);
    padding: 0 var(--spacing-base);
  }
}
