@import 'styles/colors.module.scss';

.vaultModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.vaultModal {
  width: 100%;
  max-width: 1240px;
  background-color: var(--grey-darkest);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  outline: none;
  transform: translateY(-50px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);
  @media (max-width: 520px) {
    margin: 0 var(--spacing-xs);
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-darkest);
  border-bottom: solid 1px var(--grey-dark);
  position: relative;
  height: 60px;
  .title {
    display: flex;
  }
  .icon {
    margin-right: 8px;
  }
  .closeIcon {
    padding: var(--spacing-2sm);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateY(0px);
}

.beforeClose {
  transform: translateY(50px);
}

@keyframes floatUp {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-10px, -20px);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.copy {
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--grey-darker);
  margin-bottom: var(--spacing-sm);
}

.nextRollUp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--grey-darker);
  padding: var(--spacing-xs) var(--spacing-lg);
  flex: 1;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.mainnetTime {
  line-height: 1.2em;
  @supports (-webkit-background-clip: text) {
    background: linear-gradient(90deg, orange, crimson);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
}

.countdown {
  border: solid 1px rgba(orangered, 0.5);
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  padding: 0 var(--spacing-2xs);
  border-radius: 3px;
  .time {
    font-stretch: expanded;
    width: 100px;
    text-align: right;
  }
  svg {
    fill: orangered !important;
    margin-right: 4px;
  }
  .label {
    margin-right: var(--spacing-sm);
  }
}

.body {
  height: 60vh;
  overflow-y: auto;
  padding: var(--spacing-sm) var(--spacing-lg);
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: var(--spacing-sm);
  &.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: var(--spacing-lg);
  }
}

.emptyContent {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  border-radius: var(--border-radius);
  position: relative;
  width: 100%;
}

.icon {
  fill: var(--immutable) !important;
  margin-right: 4px;
}

.reactTabs {
  flex: 1;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: var(--breakpoint-desktop);
  .tabList {
    display: flex;
    border: 1px solid var(--grey-dark);
    border-width: 0 0 1px 0;
    height: 50px;
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    z-index: 1;
    background-color: var(--grey-darkest);
    padding: 0 var(--spacing-lg);
    display: flex;
    //justify-content: center;
    align-items: center;
    .arrowIcon {
      //border: solid 1px red;
      margin: 0 var(--spacing-md);
      background-color: var(--grey-dark);
      border-radius: 4px;
      padding: 4px;
    }
    .tab {
      //margin-right: var(--spacing-lg);
      transition: color 0.2s ease-in-out;
      justify-content: center;
      align-items: center;
      display: flex;
      position: relative;
      height: 50px;
      span {
        background-color: var(--grey);
        padding: 0 6px;
        min-width: 16px;
        border-radius: 20px;
        font-size: 10px;
        margin-left: var(--spacing-2xs);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        height: 16px;
        line-height: 16px;
        text-align: center;
      }
      span.primary {
        background-image: var(--primary-gradient);
        background-color: transparent;
        color: #fff !important;
        font-weight: 700;
      }
      .icon {
        margin-right: var(--spacing-2xs);
        fill: var(--text-muted) !important;
      }
      &:hover {
        cursor: pointer;
        color: #fff;
      }
      &.tabSelected {
        color: #fff;
        .icon {
          fill: #fff !important;
        }
        &:after {
          content: '';
          width: 100%;
          position: absolute;
          bottom: 0;
          background-image: var(--primary-gradient);
          height: 2px;
          z-index: 1;
          animation: grow 0.2s ease-in-out forwards;
          animation-direction: normal;
          animation-delay: 0.1s;
          transform-origin: 50%;
          opacity: 0;
        }
        @keyframes grow {
          from {
            transform: scaleX(0);
            opacity: 0;
          }
          to {
            transform: scaleX(1);
            opacity: 1;
          }
        }
      }
    }
  }
  .panels {
    flex: 1;
    display: flex;
    flex-direction: column;
    :global(.react-tabs__tab-panel--selected) {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
