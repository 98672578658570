@import 'styles/breakpoints.module.scss';

.currencyIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  @media screen and (max-width: $breakpoint-laptop) {
    // Gradients simply doesn't show on smaller screens for some reason
    // therefore the icon doesn't render at all
    svg path:nth-of-type(1) {
      fill: var(--primary);
    }
    svg path:nth-of-type(2) {
      fill: var(--secondary);
    }
  }
}
