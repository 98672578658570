@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fade-in($duration: var(--transition-short)) {
  animation: fade-in $duration ease-out;
}
