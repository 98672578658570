.timer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  .timeBlocks {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    span {
      display: flex;
      flex-direction: column;
    }
  }
  strong {
    color: var(--text-muted);
    font-size: 14px;
    font-weight: 400;
    display: inline-flex;
    font-family: var(--font-primary);
  }
  .label {
    white-space: pre-line;
  }

  &.timerInline {
    .time strong {
      margin: 0 var(--spacing-2xs);
    }
  }
}
