@import 'styles/index.scss';
@import 'styles/breakpoints.module.scss';

::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  background: var(--grey-dark);
}
::-webkit-scrollbar-thumb {
  border: 0 !important;
  border-radius: 20px;
  background-image: var(--primary-gradient);
}

.layout {
  height: 100%;
  //padding-top: 64px;
  display: grid;
  justify-items: center;
  grid-template-rows: 1fr auto;
  //background: url(../../assets/backgrounds/mainBG-small.jpg) center top no-repeat;
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
}

.main {
  width: 100%;
  display: block;
  padding: 0 30px;
  max-width: var(--breakpoint-desktop);
  // grid-template-columns:
  //   10px
  //   calc(min(var(--breakpoint-desktop), 100% - 2 * var(--spacing-base)))
  //   10px;
  // column-gap: var(--spacing-base);
  position: relative;
  //background: rgba($grey-darkest, 0.5);

  > * {
    grid-column: 2;
  }

  :global(.full-bleed) {
    width: 100%;
    //max-width: calc(var(--breakpoint-desktop) + 480px);
    margin: 0 auto;
    grid-column: 1 / -1;
  }
  &.landingMain {
    padding: 0;
    max-width: 100vw !important;
  }
}

.landing {
  background-color: tomato;
}

.footer {
  background-color: var(--grey-darkest);
  border-top: solid 1px var(--grey-darker);
  width: 100%;
  padding: var(--spacing-sm) var(--spacing-lg);
  display: flex;
  justify-content: center;
  //margin-top: var(--spacing-md);
  .inner {
    max-width: var(--breakpoint-desktop);
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      color: #fff;
      margin: 0 8px;
      text-decoration: underline;
      &:hover {
        color: var(--text);
      }
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .main {
    padding: 0 10px;
  }
}
// @media (min-width: #{$breakpoint-largedesktop}) {
//   .main {
//     max-width: calc(100vw - 20px);
//   }
// }
