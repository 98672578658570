@import 'styles/colors.module.scss';
@import 'styles/transitions.scss';

.editProfileModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.editProfileModal {
  width: 100%;
  max-width: 600px;
  background-color: var(--grey-darkest);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  outline: none;
  transform: translateY(-50px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);

  @media (max-width: 520px) {
    margin: 0 var(--spacing-xs);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-darkest);
    border-bottom: solid 1px var(--grey-dark);
    position: relative;
    height: 60px;

    .closeIcon {
      padding: var(--spacing-2sm);
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-base);
    .balanceContainer {
      margin-bottom: var(--spacing-lg);
      border-radius: var(--border-radius-small);
      display: flex;

      .wallet {
        border: solid 2px var(--grey-dark);
        border-left-width: 0;
        height: 170px;
        border-radius: 0 var(--border-radius-small) var(--border-radius-small) 0;
        flex: 1;
        position: relative;
        padding: var(--spacing-base);
        display: flex;
        justify-content: space-evenly;
        align-items: flex-end;
        flex-direction: column;

        .walletBalance {
          display: flex;
          align-items: center;
          margin-bottom: var(--spacing-sm);
          .ethIcon {
            border: solid 3px var(--grey-mid);
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            margin-right: 10px;
          }
          .networkInfo {
            display: flex;
            flex-direction: column;
            .walletAddress {
              text-align: right;
            }
          }
        }
      }
      .balance {
        flex: 0.8;
        height: 170px;
        border-radius: var(--border-radius-small) 3px 3px
          var(--border-radius-small);
        border: solid 2px var(--secondary);
        border-right-width: 0;
        padding: var(--spacing-base);
        justify-content: space-evenly;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          top: 0;
          height: 55%;
          width: 2px;
          background-image: linear-gradient(var(--secondary), var(--primary));
          transform: rotate(-23deg);
          transform-origin: 0% 0%;
        }
        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: -2px;
          height: 55%;
          width: 2px;
          background-image: linear-gradient(var(--primary), var(--secondary));
          transform: rotate(23deg);
          transform-origin: 100% 100%;
        }
        .kolectivBalance {
          display: flex;
          margin-bottom: var(--spacing-xs);
          .appLogo {
            display: inline-block;
            object-fit: contain;
            height: 30px;
            vertical-align: middle;
          }
        }
      }
    }
    .uploadAvatar {
      display: flex;
      align-self: center;
      justify-content: center;
      margin: var(--spacing-base) 0 var(--spacing-lg);
      border: 3px dashed var(--grey);
      border-radius: 50%;
      padding: var(--spacing-xs);
      transition: border-color var(--transition-short);
      cursor: pointer;

      input[type='file'] {
        display: none;
      }

      .avatarContainer {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .spinner {
          z-index: 2;
        }

        &.avatarUploading::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($grey-darkest, 0.8);
          z-index: 1;
        }
      }

      &:hover {
        border-color: var(--primary-light);
      }
    }

    .errors {
      margin-top: var(--spacing-base);
      @include fade-in(var(--transition-long));
      & > * ~ * {
        margin-top: var(--spacing-xs);
      }
    }

    .formItem {
      display: block;
      margin-top: var(--spacing-base);

      .formLabel {
        margin-bottom: var(--spacing-xs);
      }

      .formMessage {
        line-height: 1.4;
        margin-bottom: var(--spacing-xs);
      }
    }

    .inputWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .formInput {
        text-align: center;
        font-size: var(--font-lg);
        font-weight: 700;
      }
    }
    .button {
      width: 150px;
      align-self: flex-end;
    }

    .maxButton {
      //width: 80px;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateY(0px);
}

.beforeClose {
  transform: translateY(50px);
}

.blocks {
  margin-top: 15px;
  display: inline;
}

.totalBlock {
  float: left;
  .totalLabel {
    margin-bottom: var(--spacing-2xs);
  }
}

.buttonBlock {
  float: right;
}
