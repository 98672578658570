.offer {
  display: flex;
  align-items: center;
  padding: var(--spacing-base);
  border-bottom: 1px solid var(--grey-darker);
}

.cardImage {
  width: 100px;
}

.content {
  margin-left: var(--spacing-base);

  .cardName {
    margin-bottom: var(--spacing-3xs);
  }

  .user {
    display: flex;
    align-items: center;
    margin: var(--spacing-sm) 0;

    .userName {
      margin-left: 4px;
    }

    .price {
      margin-right: var(--spacing-xs);
    }
    .offered {
      margin-right: var(--spacing-sm);
    }
  }

  .actions {
    display: flex;
    align-items: center;
    margin: var(--spacing-base) calc(-1 * var(--spacing-xs)) 0;

    .action {
      height: 30px;
      padding: 0 var(--spacing-sm);
      margin-right: var(--spacing-sm);
      min-width: 120px;
    }
  }
}
