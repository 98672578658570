@import 'styles/colors.module.scss';

@mixin button-bg($bg) {
  background-color: var(--tertiary);
  &:hover {
    background-position: 100% 0 !important;
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: var(--white);
  background-color: transparent;
  border-radius: var(--border-radius);
  font-family: var(--font-family);
  font-weight: var(--font-bold);
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 0.2s ease-in-out;

  height: 40px;
  padding: 0 var(--spacing-sm);
  font-size: var(--font-base);
  &:active {
    transform: translateY(1px) !important;
  }
  .buttonContent {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  &:hover {
    filter: brightness(1.2);
  }
}

.block {
  display: flex;
  width: 100%;
}

.immutableButton {
  background-image: var(--immutable-gradient);
  svg {
    fill: white !important;
  }
  &::before {
    display: none;
  }
  &:hover {
    filter: brightness(1.1);
  }
}
.eth,
.ethButton {
  background-image: var(--eth-gradient);
  svg {
    fill: white !important;
  }
  &::before {
    display: none;
  }
  &:hover {
    filter: brightness(1.1);
  }
}

.uppercase {
  text-transform: uppercase;
}

.sm {
  height: 32px;
  padding: 0 var(--spacing-sm);
  font-size: var(--font-xs);
}

.lg {
  height: 50px;
  padding: 0 var(--spacing-base);
  font-size: var(--font-sm);
}

.primary {
  position: relative;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  &::before {
    content: '';
    background-image: linear-gradient(90deg, $tertiary, $secondary);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    transform: scale(1);
    &::before {
      transform: scale(2) translateX(20px);
    }
  }
  &:active:not(:disabled) {
    transform: scale(0.99) translateY(2px);
  }
}

.eth {
  background-color: $eth;
  &:hover {
    background-color: scale-color($eth, $lightness: 20%);
  }
}

.edit {
  background-color: transparent;
  border: solid 1px var(--text-muted);
  &:hover {
    border-color: white;
  }
}

.negative {
  background-color: crimson;

  &:hover {
  }
}

.secondary {
  background-color: $secondary;
  &:hover {
    background-color: scale-color($grey-dark, $lightness: 10%);
  }
}

.muted {
  background-color: $grey-dark;
  &:hover {
    background-color: scale-color($grey-dark, $lightness: 10%);
  }
}

.roundedFull {
  border-radius: var(--border-radius-full);
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading {
  &.sm {
    min-width: 160px;
  }
  &.lg {
    min-width: 200px;
  }
}
