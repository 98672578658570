@import 'styles/colors.module.scss';
@import 'styles/_mixins.scss';
@import 'styles/breakpoints.module.scss';

.transactionsDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent,
    rgba(0, 0, 0, 1)
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.drawer {
  @include scrollbars;
  height: calc(100% - 40px);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-darkest);
  overflow: hidden;
  outline: none;
  transform: translateX(500px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);
  margin-right: 20px;

  .header {
    display: flex;
    align-items: center;
    background-color: var(--grey-darker70);
    position: relative;
    height: 60px;
    flex-shrink: 0;
    padding: 0 var(--spacing-2xs) 0 var(--spacing-base);
    box-shadow: 0 2px 0 0 var(--grey-darkest);
    z-index: 1;

    .closeIcon {
      padding: var(--spacing-2sm);
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    position: relative;
    overflow-y: auto;

    .transaction {
      display: flex;
      align-items: center;
      padding: var(--spacing-xs) var(--spacing-sm);
      border-bottom: solid 1px var(--grey-dark);

      & ~ .transaction {
        margin-top: var(--spacing-3xs);
      }

      .direction {
        margin-right: var(--spacing-sm);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;

        .positiveDirection {
          background: rgba(greenyellow, 0.25);
          flex: 1;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius-full);
          svg {
            transform: rotate(90deg);
          }
        }

        .negativeDirection {
          background: rgba(crimson, 0.25);
          flex: 1;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: var(--border-radius-full);
          svg {
            transform: rotate(-90deg);
          }
        }
      }

      .left {
        flex-grow: 1;
        .description {
          margin-top: var(--spacing-2xs);
        }
      }

      .right {
        text-align: right;
        flex-basis: 10ch;
        margin-left: var(--spacing-base);
        margin-right: var(--spacing-2xs);
      }
    }

    .emptyText {
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%);
    }

    .spinner {
      top: 10%;
      bottom: unset;
    }

    .pageSpinner {
      margin: var(--spacing-base) auto;
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateX(0px);
}

.beforeClose {
  transform: translateX(250px);
}

@media (max-width: #{$breakpoint-tablet}) {
  .drawer {
    height: 100%;
    margin-right: 0;
    border-radius: 0;
  }
}
