.userBalance {
  height: 40px;
  border-radius: 20px;
  padding: 0 var(--spacing-sm) 0 var(--spacing-sm);
  background-color: var(--grey-darker);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: var(--transition-short-ease);
  cursor: pointer;

  &:hover:not(.navItemActive) {
    background-color: var(--grey-dark);
  }

  .balance {
    margin-left: 0;
    margin-right: var(--spacing-2xs);
  }
}

.menuButton {
  width: 60px;
  height: 60px;
  border: solid 1px var(--grey-dark);
  border-width: 0 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition-short-ease);

  &:hover {
    background-color: var(--grey-darker);
    .navItemLabel {
      color: var(--text);
    }
  }
}

.menu {
  .positiveDirection {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transform: rotate(90deg);
    }
  }
  .negativeDirection {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transform: rotate(-90deg);
    }
  }
}
