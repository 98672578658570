@keyframes slideIn {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideUpLarge {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideDownLarge {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes movement {
  0% {
    transform: rotateY(-20deg) rotateX(7deg) rotateZ(0deg);
  }

  50% {
    transform: rotateY(160deg) rotateX(-7deg) rotateZ(0deg);
  }
  100% {
    transform: rotateY(340deg) rotateX(7deg) rotateZ(0deg);
  }
}
