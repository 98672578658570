.avatar {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .defaultAvatar {
    position: absolute;
  }
  .fallbackWrapper {
    background-color: var(--grey);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    // box-shadow: inset 0 0 0 2px var(--grey-mid),
    //   inset 0 0 0 4px var(--grey-dark);
  }
  .fallbackSvg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: popIn 0.1s forwards ease-in-out;
    animation-delay: 0.1s;
    width: 50%;
    height: 50%;
    opacity: 0;
    fill: var(--grey-darkest) !important
  }

  @keyframes popIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .userAvatar {
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    &.full {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .squared {
    border-radius: var(--border-radius);
    .fallbackWrapper {
      border-radius: var(--border-radius);
    }
    .userAvatar {
      border-radius: var(--border-radius);
    }
  }
}
