@import 'styles/colors.module.scss';
@import 'styles/breakpoints.module.scss';

@keyframes floatUp {
  0% {
    transform: translate3d(0px, 0px, 0px) rotateX(4deg);
  }
  100% {
    transform: translate3d(-10px, -20px, 0px) rotateX(-2deg);
  }
}
@keyframes jump {
  from {
    transform: scale(1);
    border: solid 1px blue;
  }
  to {
    transform: scale(10);
    border: solid 1px blue;
  }
}

.buyPackModal {
  background: $grey-darker;
  max-width: 940px;
  width: 100%;
  overflow: visible;
  //box-shadow: 0 0 40px 10px black;

  .buyPackModalHeader {
    display: none !important;
    // @media screen and (min-width: $breakpoint-tablet) {
    //   display: unset;
    // }
  }

  .buyPackModalContent {
    width: 100%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: var(--spacing-base);
    overflow-y: visible;
    perspective: 1200px;

    .packImage {
      display: block;
      width: 100%;
      max-width: 300px;
      max-height: 60vmin;
      object-fit: contain;
      margin-bottom: var(--spacing-2xl);
    }

    .right {
      width: 100%;
      max-width: 80vw;
      flex: 1;

      p {
        line-height: 1.5;
        margin-top: var(--spacing-2xs);
      }

      .currentPrice {
        margin-top: var(--spacing-base);
        .packcount {
          color: white;
        }

        h3 {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          margin: 0;
          margin-bottom: 2px;

          & > :first-child {
            margin-right: 1ch;
          }

          svg {
            width: 1.5em;
            height: 1.5em;
            display: inline-block;
            position: relative;
            top: 0.3em;
            fill: var(--text-muted) !important;
          }
        }
      }

      .packCost {
        display: inline-flex;

        &__current {
          margin-top: 2px;
        }
        &__total {
          margin-right: 2ch;
        }

        $duration: 0.75s;

        & + svg.arrow {
          transform: rotate(-90deg);
          fill: var(--primary) !important;
          position: relative;
          top: 0.2em;
          transform: rotate(-90deg);
          opacity: 0;
        }

        &.update {
          animation: flash $duration ease-in-out alternate 5;
          & + svg.arrow {
            opacity: 0;
            animation: flash $duration ease-in-out alternate 5,
              up $duration both 5;
            animation-fill-mode: none;
            transition: opacity 0.1s;
            margin: 0 var(--spacing-xs) 0 var(--spacing-3xs);

            @keyframes up {
              from {
                transform: translateY(2px) rotate(-90deg);
              }
              to {
                transform: translateY(-2px) rotate(-90deg);
              }
            }
          }
        }

        @keyframes flash {
          0%,
          100% {
            opacity: 1;
          }
          50% {
            opacity: 0.2;
          }
        }
      }

      .amountLabel {
        margin-top: var(--spacing-base);
      }

      .amountSliderRow {
        display: flex;
        align-items: center;
        margin-top: var(--spacing-sm);

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
          .amountSlider {
            pointer-events: none;
          }
        }

        .amountSlider {
          :global {
            .rc-slider-rail {
              height: 5px;
              background-color: var(--grey);
            }
            .rc-slider-track {
              height: 5px;
              background-image: var(--primary-gradient);
            }
            .rc-slider-handle {
              width: 16px;
              height: 16px;
              border: none;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba($color: #fff, $alpha: 0.75);
              transition: background-color var(--transition-short-ease);
              &::after {
                content: '';
                position: absolute;
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: rgba($color: #fff, $alpha: 0.2);
                transition: background-color var(--transition-short-ease);
              }
              &:hover {
                background-color: #fff;
                &::after {
                  background-color: rgba($color: #fff, $alpha: 0.4);
                }
              }
            }
          }
        }

        .amountValue {
          width: 40px;
          text-align: right;
          margin-left: var(--spacing-base);
        }
      }

      .totalLabel {
        margin-top: var(--spacing-base);
      }
    }

    @media screen and (min-width: $breakpoint-tablet) {
      flex-direction: row;
      justify-content: center;

      .packImage {
        margin-bottom: unset;
        margin: 0 auto;
      }

      .right {
        margin: 0 var(--spacing-xl);
        max-width: 500px;

        .amountSliderRow {
          max-width: 450px;
        }
      }
    }

    @media screen and (min-width: $breakpoint-laptop) {
      .packImage {
        max-height: unset;
      }
      .right {
        margin: 0 auto;
      }
    }
  }
}

.botttomInfo {
  display: flex;
  flex-direction: column;
  .totalRemainingText {
    margin-top: var(--spacing-sm);
    .totalCount {
      width: auto;
      font-weight: 800;
      margin-right: 4px;
      transform: translateY(1px);
    }
  }
  .saleEndsText {
    margin-top: 20px;
  }
}

.presaleEnds {
  margin-top: var(--spacing-xs);
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: var(--text-muted);
  font-family: var(--font-primary);
  .presaleEndCountdown {
    margin-left: var(--spacing-2xs);
  }
}

.dropBegins {
  margin-top: var(--spacing-xs);
}

.gradientText {
  color: var(--primary);
  @supports (-webkit-background-clip: text) {
    display: inline-block; // maintains gradient across text-width instead of block width
    background: linear-gradient(135deg, var(--primary), var(--secondary));
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
}
