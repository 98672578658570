@import 'styles/colors.module.scss';
@import 'styles/breakpoints.module.scss';
@import 'styles/_mixins.scss';

.packsDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent,
    rgba(0, 0, 0, 1)
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.drawer {
  @include scrollbars;
  height: calc(100% - 40px);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-darkest);
  overflow: hidden;
  outline: none;
  transform: translateX(500px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);
  margin-right: 20px;

  .header {
    display: flex;
    align-items: center;
    background-color: var(--grey-darker70);
    position: relative;
    height: 60px;
    flex-shrink: 0;
    padding: 0 var(--spacing-2xs) 0 var(--spacing-base);
    box-shadow: 0 2px 0 0 var(--grey-darkest);
    z-index: 1;

    .packImage {
      width: 24px;
      margin-right: 8px;
    }

    .count {
      background: var(--primary);
      display: inline-flex;
      justify-content: center;
      border-radius: 1.5em;
      min-width: 2ch;
      margin-left: var(--spacing-3xs);
      padding: 1px 0.5ch;
      position: relative;
      bottom: 2px;
    }

    .closeIcon {
      padding: var(--spacing-2sm);
      cursor: pointer;
      margin-left: auto;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    position: relative;

    .navItems {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }

    .endText {
      color: var(--text-muted);
      margin: var(--spacing-4xl) auto;
    }

    .pageSpinner {
      margin: var(--spacing-base) auto;
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateX(0px);
}

.beforeClose {
  transform: translateX(250px);
}

@media (max-width: #{$breakpoint-tablet}) {
  .drawer {
    height: 100%;
    margin-right: 0;
    border-radius: 0;
  }
}
