$breakpoints: (
  'mobile': 414px,
  'tablet': 768px,
  'laptop': 1280px,
  'desktop': 1600px,
  'largedesktop': 2100px,
  'full': 2400px,
);

$breakpoint-mobile: map-get($breakpoints, 'mobile');
$breakpoint-tablet: map-get($breakpoints, 'tablet');
$breakpoint-laptop: map-get($breakpoints, 'laptop');
$breakpoint-desktop: map-get($breakpoints, 'desktop');
$breakpoint-largedesktop: map-get($breakpoints, 'largedesktop');
$breakpoint-full: map-get($breakpoints, 'full');


:export {
  mobile: $breakpoint-mobile;
  tablet: $breakpoint-tablet;
  laptop: $breakpoint-laptop;
  desktop: $breakpoint-desktop;
  largedesktop: $breakpoint-largedesktop;
  full: $breakpoint-full;
}
