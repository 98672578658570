@import 'styles/colors.module.scss';

.loginModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.loginModal {
  width: 100%;
  max-width: var(--breakpoint-desktop);
  height: 100vh;
  outline: none;
  opacity: 0;
  transition: opacity 300ms ease-out;
  perspective: 1200px;
  border-radius: var(--border-radius-small);
  display: flex;
  justify-content: center;

  @media (max-width: 420px) {
    margin: 0 var(--spacing-xs);
  }

  .dismiss {
    position: absolute;
    top: 15%;
    right: 0;
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px rgba(255,255,255,0.1);
    background: rgba(255,255,255,0.05);
    border-radius: var(--border-radius-full);
    padding: 10px 20px;
    .closeIcon {
      margin-left: 4px;
    }
    &:hover {
      color: white;
      border: solid 1px rgba(255,255,255,0.2);
      svg {
        fill: white !important;
      }
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .packCount {
    background-image: var(--primary-gradient);
    height: 40px;
    padding: 0 16px 0 12px;
    border-radius: 50px;
    position: absolute;
    right: 35%;
    top: 35%;
    z-index: 2;
    line-height: 40px;
    animation: floatUp 6s infinite ease-in-out alternate;
    display: flex;
    flex-direction: row;
    &::before {
      content: '\00D7';
      color: #fff;
      font-size: 24px;
      line-height: 40px;
      margin-right: 3px;
      font-weight: 700;
    }
  }
  .packImage {
    transition: var(--transition-short-ease);
    transform: translate(0) rotateY(0deg) rotateX(0deg) rotateZ(0deg);
    width: 260px;
    margin-bottom: 20px;
    animation: floatUp 6s infinite ease-in-out alternate;
    z-index: 1;
  }
  .sunBeamWrapper {
    position: absolute;

    margin-top: -60px;
  }
  .sunBeam {
    z-index: 0;
    animation: rotate 30s infinite linear;
    opacity: 0.3;
  }
  .successText {
    margin-bottom: 10px;
    z-index: 1;
    background-image: linear-gradient(to right, green, yellow);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  .successSubtext {
    z-index: 1;
  }

  .link {
    margin-top: 20px;
    text-decoration: underline;
    z-index: 1;
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  opacity: 1;
}

.beforeClose {
  opacity: 0;
}

@keyframes floatUp {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-10px, -20px);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
