.currencyValue {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .currencyText {
    display: flex;
    align-items: flex-end;
    .usdClass {
      margin-left: 4px;
    }
    &.isColumn {
      flex-direction: column;
      align-items: flex-end;
    }
    &.isLeft {
      flex-direction: column;
      align-items: flex-start;
      .usdClass {
        margin: 0;
      }
    }
  }
  &.isInline {
    width: auto;
    display: inline-block;
    vertical-align: middle;
    .value {
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .value {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
}
