@import 'colors.module.scss';
@import 'breakpoints.module.scss';

:root {
  --primary: #{$primary};
  --primary-dark: #{$primary-dark};
  --primary-light: #{$primary-light};
  --secondary: #{$secondary};
  --secondary-dark: #{$secondary-dark};
  --secondary-light: #{$secondary-light};
  --tertiary: #{$tertiary};
  --tertiary-dark: #{$tertiary-dark};
  --tertiary-light: #{$tertiary-light};
  --text: #{$text};
  --text-muted: #{$text-muted};
  --white: #{$white};
  --grey: #{$grey};
  --grey-mid: #{$grey-mid};
  --grey-dark: #{$grey-dark};
  --grey-dark70: #{rgba($grey-dark, 0.7)};
  --grey-darker: #{$grey-darker};
  --grey-darker70: #{rgba($grey-darker, 0.7)};
  --grey-darkest: #{$grey-darkest};
  --grey-darkest70: #{rgba($grey-darkest, 0.7)};
  --grey-darkest90: #{rgba($grey-darkest, 0.9)};
  --transparent: #{$transparent};
  --gold: #{$gold};
  --gold-dark: #{$gold-dark};
  --silver: #{$silver};
  --silver-dark: #{$silver-dark};
  --bronze: #{$bronze};
  --bronze-dark: #{$bronze-dark};
  --common: #{$common};
  --uncommon: #{$uncommon};
  --rare: #{$rare};
  --epic: #{$epic};
  --legendary: #{$legendary};
  --mythic: #{$mythic};
  --exotic: #{$exotic};
  --immutable: #{$immutable};
  --eth: #{$eth};

  --primary-gradient: linear-gradient(135deg, var(--primary), var(--secondary));
  --primary-gradient-rev: linear-gradient(
    135deg,
    var(--secondary),
    var(--primary)
  );
  --block-gradient-one: linear-gradient(135deg, var(--grey), var(--grey-dark));
  --block-gradient-two: linear-gradient(135deg, var(--grey-dark), var(--grey-darkest));

  --immutable-gradient: linear-gradient(135deg, var(--immutable), var(--rare));
  --eth-gradient: linear-gradient(135deg, var(--legendary), var(--eth));

  --gradient-common: linear-gradient(90deg, #ffb272, #772c1d);
  --gradient-uncommon: linear-gradient(90deg, #9fe99f, #2c8b2c);
  --gradient-rare: linear-gradient(90deg, #1ed0fc, #0261e0);
  --gradient-epic: linear-gradient(90deg, #eb02e1, #a71a5c);
  --gradient-legendary: linear-gradient(90deg, #ffdf3d, #ff7406);
  --gradient-mythic: linear-gradient(90deg, #4ff8d6, #00e0b4);
  --gradient-exotic: linear-gradient(90deg, #f85a02, #ff1914);

  --spacing-7xl: 90px;
  --spacing-6xl: 80px;
  --spacing-5xl: 70px;
  --spacing-4xl: 60px;
  --spacing-3xl: 50px;
  --spacing-2xl: 40px;
  --spacing-xl: 35px;
  --spacing-lg: 30px;
  --spacing-md: 25px;
  --spacing-base: 20px;
  --spacing-sm: 15px;
  --spacing-2sm: 12px;
  --spacing-xs: 10px;
  --spacing-2xs: 5px;
  --spacing-3xs: 2px;

  --border-radius-small: 8px;
  --border-radius: 14px;
  --border-radius-full: 9999px;

  --border-solid: 1px solid var(--grey);

  --transition-short: 150ms;
  --transition-medium: 300ms;
  --transition-long: 450ms;

  --transition-short-ease: 150ms ease-out;
  --transition-short-ease-in-out: 150ms ease-in-out;

  --font-primary: 'Poppins', sans-serif;
  --font-number: 'JetBrains Mono', monospace;
  --font-title: 'tussilago', 'Poppins', sans-serif;

  --font-7xl: 72px;
  --font-6xl: 64px;
  --font-5xl: 56px;
  --font-4xl: 48px;
  --font-3xl: 40px;
  --font-2xl: 32px;
  --font-xl: 24px;
  --font-lg: 20px;
  --font-md: 18px;
  --font-base: 16px;
  --font-sm: 14px;
  --font-xs: 12px;
  --font-2xs: 10px;
  --font-3xs: 8px;

  --font-black: 900;
  --font-extrabold: 800;
  --font-bold: 700;
  --font-semibold: 600;
  --font-medium: 500;
  --font-regular: 400;
  --font-light: 300;
  --font-extralight: 200;
  --font-thin: 100;

  --breakpoint-mobile: #{$breakpoint-mobile};
  --breakpoint-tablet: #{$breakpoint-tablet};
  --breakpoint-laptop: #{$breakpoint-laptop};
  --breakpoint-desktop: #{$breakpoint-desktop};

  --z-index-modal: 10;

  @media screen and (max-width: $breakpoint-laptop) {
    --spacing-7xl: 80px;
    --spacing-6xl: 70px;
    --spacing-5xl: 60px;
    --spacing-4xl: 50px;
    --spacing-3xl: 40px;
    --spacing-2xl: 34px;
    --spacing-xl: 28px;
    --spacing-lg: 22px;
    --spacing-md: 18px;
    --spacing-base: 14px;
    --spacing-sm: 12px;
    --spacing-2sm: 10px;
    --spacing-xs: 6px;
    --spacing-2xs: 4px;
    --spacing-3xs: 2px;

    --border-radius: 10px;

    --font-7xl: 68px;
    --font-6xl: 60px;
    --font-5xl: 52px;
    --font-4xl: 44px;
    --font-3xl: 36px;
    --font-2xl: 28px;
    --font-xl: 22px;
    --font-lg: 18px;
    --font-md: 16px;
    --font-base: 14px;
    --font-sm: 12px;
    --font-xs: 10px;
    --font-2xs: 8px;
    --font-3xs: 8px;
  }
}
