.text {
  margin: 0;
}

.block {
  display: block;
}
.centered {
  text-align: center;
}
.ellipsize {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.underlined {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

.primary {
  color: var(--primary);
}
.secondary {
  color: var(--secondary);
}
.muted {
  color: var(--text-muted);
}
.white {
  color: var(--white);
}

.xxxl {
  font-size: var(--font-3xl);
}
.xxl {
  font-size: var(--font-2xl);
}
.xl {
  font-size: var(--font-xl);
}
.lg {
  font-size: var(--font-lg);
}
.md {
  font-size: var(--font-md);
}
.base {
  font-size: var(--font-base);
}
.sm {
  font-size: var(--font-sm);
}
.xs {
  font-size: var(--font-xs);
}
.xxs {
  font-size: var(--font-2xs);
}
.xxxs {
  font-size: var(--font-3xs);
}

.black {
  font-weight: var(--font-black);
}
.extrabold {
  font-weight: var(--font-extrabold);
}
.bold {
  font-weight: var(--font-bold);
}
.semibold {
  font-weight: var(--font-semibold);
}
.medium {
  font-weight: var(--font-medium);
}
.regular {
  font-weight: var(--font-regular);
}
.light {
  font-weight: var(--font-light);
}
.extralight {
  font-weight: var(--font-extralight);
}
.thin {
  font-weight: var(--font-thin);
}
