.icon {
  fill: var(--text) !important;
  color: var(--text) !important;

  &.primary {
    fill: var(--primary) !important;
    color: var(--primary) !important;
  }

  &.secondary {
    fill: var(--secondary) !important;
    color: var(--secondary) !important;
  }

  &.muted {
    fill: var(--text-muted) !important;
    color: var(--text-muted) !important;
  }

  &.white {
    fill: var(--white) !important;
    color: var(--white) !important;
  }
}
