@import 'styles/colors.module.scss';

.confirmationModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($grey-darkest, 0.8);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

}

.confirmationModal {
  //min-height: 250px;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-darkest);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  outline: none;
  transform: translateY(-50px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);
  width: 500px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 50px;
  border-bottom: solid 1px var(--grey-dark);

  .closeIcon {
    padding: var(--spacing-2sm);
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}

.content {
  flex: 1;
  padding: var(--spacing-base);
  position: relative;
}

.actions {
  display: flex;
  padding: var(--spacing-base);
  border-top: solid 1px var(--grey-darkest);
  button {
    margin: 0 var(--spacing-2xs);
    font-size: var(--font-base);
    &:first-child {
      flex: 1;
      height: 40px;
    }
    &:last-child {
      flex: 2;
      height: 40px;
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateY(0px);
}

.beforeClose {
  transform: translateY(50px);
}
