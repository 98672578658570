.menuNavItem {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: var(--transparent);
  border-bottom: 1px solid var(--grey-darker);
  cursor: pointer;

  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid var(--grey-dark);
    background-color: var(--grey-darkest);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: var(--spacing-base);

    img {
      width: 18px;
    }
  }

  .label {
    margin-left: var(--spacing-xs);
  }

  & {
    transition: var(--transition-short-ease);
    .icon,
    .label {
      transition: var(--transition-short-ease);
    }
  }
  &:hover {
    background-color: var(--grey-dark);
    .icon {
      border-color: var(--text);
      transform: translateX(10px);
    }
    .label {
      color: var(--white);
      transform: translateX(10px);
    }
  }
}
