.vaultButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: var(--transition-short-ease);
  cursor: pointer;
  margin: 0 0 0 var(--spacing-xs) !important;
  background-color: var(--grey-darker);
  height: 40px;
  padding: 0 var(--spacing-xs) 0 var(--spacing-xs);
  border-radius: var(--border-radius-full);

  &:hover {
    background-color: var(--grey-dark);

    .vaultText {
      opacity: 1;
      transform: translateY(0px);
      visibility: visible;
    }
  }

  .lockImage {
    width: 24px;
    transition: var(--transition-short-ease);
  }
  .lockImageActive {
    width: 24px;
    transition: var(--transition-short-ease);
    fill: #fff !important;
  }

  .vaultText {
    margin-left: var(--spacing-2xs);
    transition: var(--transition-short-ease);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 30px;
    background: black;
    border-radius: 4px;
    padding: var(--spacing-xs);
    white-space: nowrap;
    z-index: 1;
    transform: translateY(-5px);

    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      transform: rotate(45deg);
      position: absolute;
      top: -3px;
      left: 50%;
      margin-left: -5px;
      background: black;
    }
  }

  .vaultContent {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 0px;
    flex: 1;

    .vaultTag {
      height: 20px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: var(--primary-gradient);
      transition: var(--transition-short-ease);
      padding: 0 8px;
      margin-left: 2px;
    }

    .vaultTagNumber {
      line-height: 20px;
      width: auto;
      max-width: 100%;
      &::before {
        content: '\00D7';
        color: #fff;
      }
    }
  }
}
