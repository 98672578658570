.appLogoContainer {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 10px;


}

.appLogo {
  display: inline-block;
  object-fit: contain;
  height: 40px;
  vertical-align: middle;
  max-width: 40px;

}
