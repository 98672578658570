.appLogoContainer {
  display: inline-block;
  justify-content: center;
  align-items: center;
  //padding: 10px;
  margin-right: var(--spacing-base);

}

.appLogo {
  display: inline-block;
  object-fit: contain;
  height: 34px;
  vertical-align: middle;
}
