.SVGCard {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  perspective: 1000;
  -webkit-perspective: 1000;
  //contain: strict;

  video {
    //object-fit: cover;
    @media (hover: none) {
      /* custom css for "touch targets" */
      width: calc(100vw - 78px) !important;
      height: calc((100vw - 80px) / 0.72) !important;
      border-radius: 16px !important;
      object-fit: cover;

      position: absolute !important;
      //left: 0;
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
