@import 'styles/colors.module.scss';
@import 'styles/transitions.scss';

.listOnMarketModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.listOnMarketModal {
  width: 100%;
  max-width: 500px;
  background-color: var(--grey-darkest);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  outline: none;
  transform: translateY(-50px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);

  @media (max-width: 420px) {
    margin: 0 var(--spacing-xs);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--grey-darkest);
    border-bottom: solid 1px var(--grey-dark);
    position: relative;
    height: 60px;

    .closeIcon {
      padding: var(--spacing-2sm);
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-lg);

    .cardImage {
      margin: 0 auto;
    }

    .packImage {
      margin: 0 auto;
    }

    .errorAlert {
      margin-top: var(--spacing-base);
    }

    .formItem {
      display: block;

      .formLabel {
        margin-bottom: var(--spacing-xs);
      }

      .input {
        font-weight: var(--font-bold);
        text-align: center;
      }

      .input::placeholder {
        color: var(--text-muted);
      }
    }

    * + .formItem {
      margin-top: var(--spacing-base);
    }

    .infoRow {
      margin-top: var(--spacing-base);
      margin-bottom: var(--spacing-base);
      justify-content: space-between;
      display: flex;
      .infoColumn {
        margin-right: var(--spacing-lg);
      }
      .infoValue {
        margin-top: var(--spacing-xs);
      }
    }

    .button {
      margin-top: var(--spacing-sm);
    }

    .bounds {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 1em;
      justify-content: space-between;
      opacity: 0.5;
      margin-top: 1ch;
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateY(0px);
}

.beforeClose {
  transform: translateY(50px);
}
