@import 'styles/colors.module.scss';
@import 'styles/animations.module.scss';

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  color: var(--text);
  background-color: var(--grey-darkest);
  font-family: var(--font-primary);
  font-weight: var(--font-regular);
  font-size: var(--font-base);
  margin: 0;
}

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

input {
  background: none;
  outline: none;
  border: none;
  color: var(--text);
  font-family: var(--font-primary);
  font-weight: var(--font-regular);
  font-size: var(--font-base);
  width: 100%;
}

.imxLinkIframeContainer {
  background: var(--grey-dark);
  position: fixed;
  right: 50% !important;
  top: 0 !important;
  bottom: auto !important;
  margin-right: -300px;
  border-radius: 0 0 20px 20px;
  overflow: hidden;
  z-index: 10;
  height: 60vh !important;
  min-height: 500px !important;
  width: 600px !important;
  animation: slideDownLarge 1s cubic-bezier(1, 0, 0.53, 1) forwards normal;
  box-shadow: 0 -10px 20px 0px black, 0 0 5000px 5000px rgba($grey-dark, 0.95);
  transition: ease-in-out all 0.5s;
  &:hover {
    box-shadow: 0 -10px 50px 0px black, 0 0 5000px 5000px rgba($grey-dark, 0.95);
  }
}

#root {
  height: 100%;
}

// default <Menu> ('@szhsin/react-menu') styles
// if you are struggling to override the specificity
// you can hack it by doubling up like `.menu.menu {...}` which would work on
// any of the following selectors as well
[role='menu'] {
  background: var(--grey-darkest);
  box-shadow: inset 0 0 0 1px var(--grey-dark);

  .arrow {
    background: var(--grey-darkest);
  }

  [role='menuitem'] {
    background: none;
    color: var(--text);
    text-transform: uppercase;
    font-weight: 500;
    font-size: var(--font-sm);
    padding: 1ch 3ch 1ch 2ch;

    svg:first-child {
      width: 1em;
      height: 1em;
      margin-right: 1ch;
    }

    &:hover {
      background: var(--grey-darker);
      color: var(--white);
    }
  }

  [role='separator'] {
    background: var(--grey-dark);
  }
}

/**
 * Disable background scrolling for any active react-modal
 * See: http://reactcommunity.org/react-modal/
 */
.ReactModal__Body--open {
  overflow: hidden;
}
