@import 'styles/colors.module.scss';

.bar {
  width: 100%;
  padding: 0 40px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0;
  z-index: 100;
}

.barText {
  font-weight: bold;
  margin-left: 12px;
}

.link {
  text-decoration: underline;
  margin-left: 30px;
}

.light {
  color: white;
}

.dark {
  color: black;
}

.progress {
  background-image: linear-gradient(to right, #fccc00, #ff980d);
}

.success {
  background-image: linear-gradient(to right, darken(#29d2ae, 10%), darken(#07ce82, 10%));
}

.failure {
  background-image: linear-gradient(to right, #fe323b, #cd018c);
}

.icon {
  color: white !important;
  fill: #fff !important;
}
