@import 'styles/index.scss';
@import 'styles/breakpoints.module.scss';

:root {
  --nav-height: 64px;
}

.appHeader {
  @include fontSmoothing;
  $border: 1px solid var(--grey-dark);
  transition: all 0.3s ease-in-out;
  background-color: rgba($grey-darkest, 1);
  border-bottom: $border;

  &::before {
    content: '';
    width: 100%;
    height: 4px;
    background: var(--primary-gradient);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  .headerNav {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: var(--nav-height);
    margin: 0 auto;
    padding: 4px 10px 0 0px;
    position: relative;
    //max-width: var(--breakpoint-desktop);

    // @media (min-width: #{$breakpoint-largedesktop}) {
    //   max-width: 100%;
    //   padding: 4px 0 0 0;
    // }
    @media (max-width: #{$breakpoint-tablet}) {
      padding: 4px 0 0 0;
    }

    &.landingNav {
      max-width: var(--breakpoint-desktop) !important;
    }

    $breakpoint: $breakpoint-tablet + 200px;

    .userNavItems {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1;
    }

    .mobileView,
    .desktopView {
      align-items: center;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    &.isMobileMenuVisible .mobileView__menu {
      opacity: 1;
      transform: scale(1);
    }

    .mobileView {
      &__menu {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background: rgba($grey-darkest, 1);
        position: fixed;
        top: var(--nav-height);
        left: 0;
        right: 0;
        padding: var(--spacing-lg) 0;
        border-top: $border;
        box-shadow: 0 100vh 0 100vh rgba($grey-darkest, 1);
        transform-origin: top;
        animation: grow 100ms ease-out;
        overflow-y: auto;
        height: calc(100% - 64px);

        .vaultButton,
        .offers,
        .packButton,
        .notifications,
        .menu,
        .login {
          margin-left: var(--spacing-xs) !important;
        }

        .avatar {
          margin: 0 var(--spacing-base);
          margin-bottom: var(--spacing-base);
          img {
            width: 80px !important;
            height: 80px !important;
          }
          & > *:first-child {
            width: 80px !important;
            height: 80px !important;
            svg {
              width: 50%;
              height: 50%;
            }
          }
        }

        .balance {
          display: inline-flex;
          width: fit-content;
          margin: 0;
          margin-bottom: var(--spacing-xl);
        }

        .navItems {
          //flex-direction: column;
          border: none !important;
        }

        .navMenuItems {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          button {
            $iconSize: 2ch;
            cursor: pointer;
            background: none;
            border: 0;
            padding-right: $iconSize;
            width: 200px;
            color: var(--text-muted);
            font-weight: var(--font-semibold);
            font-size: var(--font-base);
            height: 60px;

            svg {
              display: none;
            }

            &:hover {
              color: var(--text);
            }
          }

          hr {
            width: 100%;
            height: 0px;
            border: none;
            border-top: $border;
          }
        }
      }

      & > * {
        &.vaultButton,
        &.packButton,
        &.offers,
        &.balance,
        &.notifications,
        &.avatar,
        &.menu,
        &.login {
          margin-left: var(--spacing-base);
        }
      }

      @media screen and (min-width: $breakpoint) {
        display: none;
      }
    }
    .desktopView {
      display: none;

      .navItems {
        border-left: 1px solid var(--grey-dark);
      }

      .vaultButton,
      .packButton,
      .offers,
      .balance,
      .notifications,
      .avatar,
      .menu,
      .login {
        margin-left: var(--spacing-xs);
      }

      .navButton {
        margin-left: var(--spacing-base);
        //margin-right: var(--spacing-lg);
        font-size: var(--font-xs);
        height: 36px;
        padding: 0 var(--spacing-lg);
        flex: 1;
        @media screen and (min-width: $breakpoint-tablet) {
          font-size: var(--font-sm);
        }
        @media screen and (min-width: $breakpoint-tablet) {
          padding: 0 var(--spacing-base);
        }
      }

      @media screen and (min-width: $breakpoint) {
        display: flex;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: var(--transition-short-ease);
      cursor: pointer;
      margin: 0 0 0 var(--spacing-xs) !important;
      background-color: var(--grey-darker);
      height: 40px;
      padding: 0 var(--spacing-xs) 0 var(--spacing-xs);
      border-radius: var(--border-radius-full);

      &:hover {
        background-color: var(--grey-darker);
        .navItemLabel {
          color: var(--text);
        }
      }
    }

    .appLogo {
      flex-shrink: 0;
      border: $border;
      border-width: 0 1px;
    }
    .logo {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .appLogoFull {
        flex-shrink: 0;
      }
    }
    .spacer {
      font-size: var(--font-2xl);
      font-weight: 200;
      margin: 0px var(--spacing-base);
      opacity: 0.2;
      line-height: 20px;
      height: 20px;
      display: block;
      overflow: hidden;
    }

    .mobileMenuBtn {
      background: none;
      border: none;
      margin: 0 var(--spacing-xs);
      @media screen and (min-width: $breakpoint) {
        display: none;
      }
    }
  }

  // ANIMATIONS

  @keyframes grow {
    from {
      transform: scaleY(0);
      opacity: 0;
      pointer-events: none;
    }
    to {
      transform: scaleY(1);
      opacity: 1;
      pointer-events: all;
    }
  }
}
