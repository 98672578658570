@import 'styles/colors.module.scss';

.bar {
  border-radius: var(--border-radius-full);
  padding: var(--spacing-xs) var(--spacing-sm) var(--spacing-xs) var(--spacing-xs);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  position: fixed;
  bottom: var(--spacing-base);
  right: var(--spacing-base);
  z-index: 100;
  box-shadow: 0 0 100px 20px black;
}

.barText {
  font-weight: bold;
  margin-left: 12px;
  margin-right: 12px;
}

.link {
  margin-left: 30px;
  border: solid 1px white;
  padding: 4px 12px;
  border-radius: var(--border-radius-full);
}

.light {
  color: white;
}

.dark {
  color: black;
}

.progress {
  background-image: linear-gradient(to right, orange, darkorange);
}

.success {
  background-image: linear-gradient(to right, darken(#29d2ae, 10%), darken(#07ce82, 10%));
}

.failure {
  background-image: linear-gradient(to right, #fe323b, #cd018c);
}

.icon {
  color: white !important;
  fill: #fff !important;
}
