@import 'styles/breakpoints.module.scss';

.notificationsButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: var(--transition-short-ease);
  cursor: pointer;
  margin: 0 !important;
  background-color: var(--grey-darker);
  height: 40px;
  padding: 0 var(--spacing-xs) 0 var(--spacing-xs);
  border-radius: var(--border-radius-full);
  margin-left: var(--spacing-xs) !important;

  &:hover {
    background-color: var(--grey-dark);
  }

  .icon {
    width: 24px;
    transition: var(--transition-short-ease);
  }
  .iconActive {
    width: 24px;
    transition: var(--transition-short-ease);
    fill: #fff !important;
    color: #fff !important;
  }

  .notificationsContent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .notificationsTag {
      height: 20px;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-image: var(--primary-gradient);
      transition: var(--transition-short-ease);
      padding: 0 8px;
      margin-left: 2px;
    }

    .notificationsTagNumber {
      line-height: 20px;
      width: auto;
      max-width: 100%;
      &::before {
        content: '\00D7';
        color: #fff;
      }
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .notificationsButton {
    margin-right: var(--spacing-xs) !important;
  }
}
