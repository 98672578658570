@import 'styles/colors.module.scss';

.notifications {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  pointer-events: none;
  z-index: 9999;
}

.notification {
  //width: 100%;
  //max-width: 450px;
  padding: var(--spacing-xs) var(--spacing-base) calc(var(--spacing-xs) + 7px) var(--spacing-sm);
  border-radius: 6px;
  background-color: var(--grey-darkest);
  margin-top: var(--spacing-lg);
  margin-right: var(--spacing-lg);
  pointer-events: all;
  cursor: pointer;
  animation: slideFadeIn 0.25s cubic-bezier(1, 0, 0.53, 1);
  transition: all 0.25s cubic-bezier(1, 0, 0.53, 1);
  box-shadow: 0 0 30px rgba(0,0,0,1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  .countdown {
    height: 4px;
    background: rgba(0,0,0,0.1);
    //width: 100%;
    position: absolute;
    bottom: 3px;
    right: 3px;
    left: 3px;
    z-index: 1;
    border-radius: 4px;
    overflow: hidden;
    .time {
      background-color: var(--grey-darkest);
      height: 4px;
      width: 100%;
      animation: slideAway 5s linear forwards normal;
    }

  }
  .icon {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.1);
    border-radius: var(--border-radius-full);
    margin-right: var(--spacing-xs);
    svg {
      fill: #fff !important;
    }
  }

  .messageInfo {
    display: flex;
    flex-direction: column;
  }

  .title {
    max-width: 450px;
  }

  .subtitle {
    line-height: 1.2;
    //margin-top: var(--spacing-2xs);
    opacity: 0.7;
    //white-space: nowrap;
    text-align: left;
    max-width: 450px;
  }

  &.exiting {
    opacity: 0;
    transform: translateX(100px);
  }

  &.primary {
    background-image: linear-gradient(
      to right,
      darken(#29d2ae, 10%),
      darken(#07ce82, 10%)
    );
    .title {
      color: #fff;
      //white-space: nowrap;
      text-align: left;
    }
  }

  &.secondary {
    background-image: linear-gradient(to right, #fe323b, #cd018c);
    .title {
      color: #fff;
      //white-space: nowrap;
      text-align: left;
    }
  }

  &.processing {
    background-image: var(--immutable-gradient);
    .title {
      color: #fff;
      //white-space: nowrap;
      text-align: left;
    }
  }
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideAway {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
