@import 'styles/colors.module.scss';

.loginModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: calc(var(--z-index-modal) + 1);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.loginModal {
  width: 100%;
  max-width: 400px;
  outline: none;
  opacity: 0;
  transition: opacity 300ms ease-out;
  border-radius: var(--border-radius-small);
  padding: var(--spacing-base) var(--spacing-base) var(--spacing-xl);

  @media (max-width: 420px) {
    margin: 0 var(--spacing-xs);
  }

  .closeIcon {
    padding: var(--spacing-2sm);
    position: absolute;
    top: var(--spacing-3xl);
    right: var(--spacing-3xl);
    cursor: pointer;
  }
}

.content {
  display: flex;
  flex-direction: column;

  .appLogo {
    align-self: center;
  }

  .heading {
    margin-top: var(--spacing-2xl);
    margin-bottom: var(--spacing-base);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-xs);
  }
  .insideHeading {
    margin-right: 6px;
  }

  .form {
    display: flex;
    flex-direction: column;
  }

  .formItem {
    display: block;
    margin-top: var(--spacing-base);

    .formLabel {
      margin-bottom: var(--spacing-xs);
    }

    .formMessage {
      line-height: 1.4;
      margin-bottom: var(--spacing-xs);
    }

    .formInput {
      height: 50px;
      background-color: var(--grey-dark);
      border: 2px solid var(--grey);
      text-align: center;
    }
  }

  .switch {
    display: flex;
  }

  .switchButton {
    display: flex;
    cursor: pointer;

    height: 50px;
    margin-top: var(--spacing-2xl);
    align-items: center;
    justify-content: flex-start;

    flex: 1;
    font-size: 11px;
    font-weight: bolder;

    &:hover {
      color: white;
    }
    .mmForm {
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
      * {
        outline: none;
      }
    }
    .button {
      margin-bottom: 10px;
      height: 50px;
      margin-top: var(--spacing-2xl);
    }
  }

  .leftSwitch {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    margin-right: var(--spacing-2xl);
  }

  .rightSwitch {
    border-radius: var(--border-radius-full);
    background: var(--primary-gradient);
    justify-content: center;
    position: relative;
    z-index: 1;
    &:after {
      content: '';
      background: var(--grey-darkest);
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
      border-radius: var(--border-radius-full);
      z-index: 0;
    }
  }

  .termsText {
    text-align: center;
    line-height: 1.4em;
  }

  .active {
    color: white;
  }

  .mmForm {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideIn 0.5s cubic-bezier(1, 0, 0.53, 1) forwards normal;
    transform: translateY(10px);
    opacity: 0;
    margin-top: var(--spacing-lg);
  }

  @keyframes slideIn {
    from {
      transform: translateY(10px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .button {
    // margin-bottom: 10px;
    height: 50px;
    margin-top: var(--spacing-2xl);
  }

  .metamaskButton {
    height: 50px;
    margin: 0 auto;
    background: rgba(black, 0.8);
    border: 2px solid var(--white);
    padding-right: var(--spacing-base);
    &:before {
      display: none;
    }
    .metamaskIcon {
      margin-right: 10px;
    }
  }
  .introInfo {
    line-height: 1.6em;
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  opacity: 1;
}

.beforeClose {
  opacity: 0;
}

.bottomLink {
  align-self: center;
  margin-top: 20px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.errorAlert {
  background-image: var(--primary-gradient);
}

// web3modal
:global(.web3modal-modal-lightbox) {
  z-index: 30 !important;
}
