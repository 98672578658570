.iconContainer {
  .typeLabel {
    background-color: black;
    padding: var(--spacing-2xs) var(--spacing-2xs);
    border-radius: 3px;
    position: absolute;
    bottom: 100%;
    white-space: nowrap;
    justify-content: center;
    display: flex;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    transform: translateY(-5px);
    z-index: 1;
    &:after {
      content: '';
      background-color: black;
      width: 6px;
      height: 6px;
      position: absolute;
      bottom: -3px;
      transform: rotate(45deg);
      justify-content: center;
      display: flex;
    }
  }
  &:hover {
    cursor: pointer;
    .typeLabel {
      opacity: 1;
      visibility: visible;
    }
  }
  .icon {
    position: absolute;
    bottom: 5%;
    right: -2%;
    display: flex;
    align-items: center;
    justify-content: center;
    // z-index: 9999;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 20%;
    }
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      transform: rotate(45deg);
      border-radius: 20%;
    }
    .checkIcon {
      fill: var(--grey-dark);
      position: absolute;
      z-index: 1;
    }
  }
}

.staff {
  &::before,
  &::after {
    background-image: var(--primary-gradient);
  }
  &::after {
    background-image: linear-gradient(90deg, var(--primary), var(--secondary));
  }
  .checkIcon {
    fill: var(--white) !important;
  }
}

.artist {
  &::before,
  &::after {
    background-color: var(--secondary);
  }
  .checkIcon {
    fill: white !important;
  }
}

.founder {
  &::before,
  &::after {
    //background-color: orange;
    //background-image: var(--primary-gradient);
  }
  &::after {
    background-color: var(--primary);
  }
  .checkIcon {
    fill: white !important;
  }
  .founderIcon {
    z-index: 1;


    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    fill: #fff !important;
  }
}

.verified {
  &::before,
  &::after {
    background-color: cyan;
  }
  .checkIcon {
    fill: var(--grey-dark) !important;
  }
}
