@import 'styles/breakpoints.module.scss';

.UserInput {
  font-size: var(--font-sm);
  height: 40px;
  padding: calc(
      var(--spacing-2xs) * 1.1
        /* was just that little bit too small visually compared to the rest of the row */
    )
    var(--spacing-base);
  width: 300px;
  border-radius: var(--border-radius-full);
  color: var(--text-muted);
  &::-webkit-search-cancel-button {
    position: relative;
    right: -18px;

    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: var(--text);
    background-image: url('/shared_components/SVGIcons/icons/close_icon.svg');
    background-size: 18px;
    background-position: center;
    cursor: pointer;
  }
  &:focus {
    color: var(--text);
  }
}

[role='menu'] {
  background: var(--grey-darkest);
  box-shadow: inset 0 0 0 1px var(--grey-dark);
  max-height: 500px !important;
  overflow: auto;

  .arrow {
    background: var(--grey-darkest);
  }

  &::-webkit-scrollbar {
    background: transparent;
    width: 5px;
    background: var(--grey-dark);
  }
  &::-webkit-scrollbar-thumb {
    border: 0 !important;
    border-radius: 20px;
    background-image: var(--primary-gradient);
  }

  // menu items header
  [role='presentation'] {
    margin: 0.5ch 0;
    padding: 0 1.5em;
  }

  [role='menuitem'] {
    background: none;
    color: var(--text);
    text-transform: none;
    font-weight: 500;
    font-size: var(--font-sm);
    padding: 1ch 3ch 1ch 2ch;
    gap: 10px;

    img {
      background: var(--grey-mid);
      aspect-ratio: 1/1;
      border-radius: 50%;
      margin-right: 1ch;
    }

    &:hover {
      background: var(--grey-darker);
      color: var(--white);
    }
    &:focus {
      background: var(--grey-darker);
      color: var(--white);
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .ArtistsInput {
    width: 100%;
  }
}
