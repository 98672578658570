@import 'styles/breakpoints.module.scss';
@import 'styles/colors.module.scss';

.packsDrawerItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3 ease-in-out;
  background-image: var(--block-gradient-two);
  flex: 1;
  .packMainInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 0.3 ease-in-out;
    flex: 1;
  }

  .packImgContainer {
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1em;
    user-select: none;
    position: relative;
    transition: var(--transition-short-ease);

    img {
      width: 150px;
      height: 100%;
      max-height: 150px;
      object-fit: contain;
      @media screen and (min-width: $breakpoint-desktop) {
        max-height: 180px;
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    justify-content: center;
    align-items: flex-start;
    transition: var(--transition-short-ease);
    flex: 1;

    .badges {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--spacing-xs);
      width: 100%;

      .count {
        background-image: var(--primary-gradient);
        display: flex;
        border-radius: 1.5em;
        padding: 4px var(--spacing-xs);
        transition: opacity 100ms ease-out;
        //margin-right: var(--spacing-xs);
        position: relative;
        white-space: nowrap;
        justify-content: center;
        align-items: center;
        .removeButton {
          height: 20px;
          padding: 0 8px 0 4px;
          margin-left: 6px;
          font-size: 10px;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(darkred, 1);
          color: white;
          line-height: 1;
          border-radius: 0 30px 30px 0;
          &:hover {
            background: rgba(darkred, 0.6);
          }
          .icon {
            fill: rgba(white, 1) !important;
          }
        }
        > * {
          z-index: 1;
          position: relative;
        }
        &::before {
          content: '\00D7';
          color: #fff;
          z-index: 1;
        }
        &::after {
          content: '';
          position: absolute;
          top: 2px;
          right: 2px;
          bottom: 2px;
          left: 2px;
          background: var(--grey-darkest);
          border-radius: var(--border-radius-full);
          z-index: 0;
        }
      }
      .countListed {
        background-image: linear-gradient(to right, green, yellow);
        padding: 2px 2px 2px 10px;
      }
    }

    .name {
      width: 100%;
      margin-bottom: 4px;
      transition: all 100ms ease-out;
      text-align: left;
    }
  }
  .buttonsRow {
    display: flex;
    margin-top: var(--spacing-xs);
    justify-content: space-between;
    width: 100%;

    & > * + * {
      margin-left: 1ch;
    }
    .icon {
      fill: var(--text-muted) !important;
      margin-right: 4px;
    }
  }

  &:hover {
    .packImgContainer {
      transform: translate(-10px, 0px) scale(1);
    }
    .text {
      transform: translate(-10px, 0px);
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .buttonsRow {
    flex-direction: column;
    & > * + * {
      margin: 10px 0 0 0 !important;

    }


  }
}
