@import 'styles/transitions.scss';
@import 'styles/colors.module.scss';

@mixin alert-color($color) {
  border-left-color: $color;
  .title {
    color: $color;
    & ~ .message {
      color: var(--white);
    }
  }
  .message {
    color: $color;
  }
}

.alert {
  padding: var(--spacing-sm);
  border-radius: var(--border-radius-small);
  background-color: rgba(crimson, 0.1);
  @include fade-in(var(--transition-medium));

  .title ~ .message {
    margin-top: var(--spacing-2xs);
  }

  &.primary {
    @include alert-color(white);
  }

  &.secondary {
    @include alert-color(white);
  }
}
