@import 'styles/colors.module.scss';
@import 'styles/transitions.scss';

.editProfileModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.editProfileModal {
  width: 100%;
  max-width: 500px;
  background-color: var(--grey-darkest);
  border-radius: var(--border-radius-small);
  overflow: hidden;
  outline: none;
  transform: translateY(-50px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);

  @media (max-width: 520px) {
    margin: 0 var(--spacing-xs);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px var(--grey-dark);
    position: relative;
    height: 60px;

    .closeIcon {
      padding: var(--spacing-2sm);
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-base);

    .uploadAvatar {
      display: flex;
      align-self: center;
      justify-content: center;
      margin: var(--spacing-base) 0 var(--spacing-lg);
      border: 3px dashed var(--grey);
      border-radius: 50%;
      padding: var(--spacing-xs);
      transition: border-color var(--transition-short);
      cursor: pointer;

      input[type='file'] {
        display: none;
      }

      .avatarContainer {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        overflow: hidden;

        img {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .spinner {
          z-index: 2;
        }

        &.avatarUploading::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($grey-darkest, 0.8);
          z-index: 1;
        }
      }

      &:hover {
        border-color: var(--primary-light);
      }
    }

    .button {
      margin-top: var(--spacing-lg);
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateY(0px);
}

.beforeClose {
  transform: translateY(50px);
}
