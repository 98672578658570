@mixin fontSmoothing {
  /* Ideal for very-large headings, i.e. Landing page */
  /* Thins the text slightly, so expect increased font-weight needed */
  /* Cross browser font smoothing snippet */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

@mixin scrollbars {
  ::-webkit-scrollbar {
    background: transparent;
    width: 5px;
    background: var(--grey-dark);
  }

  ::-webkit-scrollbar-thumb {
    border: 0 !important;
    border-radius: 20px;
    background-image: var(--primary-gradient);
  }
}
