.acceptOfferModal {
  display: flex;
  flex-direction: column;
  max-width: 500px;

  .acceptOfferMsg {
    display: flex;
    line-height: var(--font-xl);
    padding: 0 var(--spacing-base);

    .acceptOfferValue {
      display: inline-flex;
      margin-right: var(--spacing-2xs);
    }
  }
}

.infoRow {
  margin-top: var(--spacing-base);
  justify-content: space-between;
  display: flex;
  .infoColumn {
    margin-right: var(--spacing-lg);
  }
  .infoValue {
    margin-top: var(--spacing-xs);
  }
}
