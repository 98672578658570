@import 'styles/colors.module.scss';
@import 'styles/breakpoints.module.scss';
@import 'styles/_mixins.scss';

.offersDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to right,
    transparent,
    transparent,
    rgba(0, 0, 0, 1)
  );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.drawer {
  @include scrollbars;
  height: calc(100% - 40px);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-darkest);
  overflow: hidden;
  outline: none;
  transform: translateX(500px);
  transition: transform 0.5s cubic-bezier(1, 0, 0.53, 1);
  margin-right: 20px;

  .header {
    display: flex;
    align-items: center;
    background-color: var(--grey-darker70);
    position: relative;
    height: 60px;
    flex-shrink: 0;
    padding: 0 var(--spacing-2xs) 0 var(--spacing-base);
    box-shadow: 0 2px 0 0 var(--grey-darkest);
    z-index: 1;

    .icon {
      margin-right: var(--spacing-2sm);
    }

    .closeIcon {
      padding: var(--spacing-2sm);
      cursor: pointer;
      margin-left: auto;
    }
  }
  .reactTabs {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    max-width: var(--breakpoint-desktop);
    max-height: calc(100vh - 100px);

    .tabList {
      display: flex;
      border: 1px solid var(--grey-dark);
      margin-top: var(--spacing-2xs);
      border-width: 0 0 1px 0;
      padding: 0 var(--spacing-base);
      height: 50px;
      position: -webkit-sticky;
      position: sticky;
      top: 64px;
      z-index: 10;
      background-color: var(--grey-darkest);
      .tab {
        margin-right: var(--spacing-lg);
        transition: color 0.2s ease-in-out;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
        .icon {
          margin-right: var(--spacing-2xs);
          fill: var(--text-muted) !important;
        }
        &:hover {
          cursor: pointer;
          color: #fff;
        }
        &.tabSelected {
          color: #fff;
          .icon {
            fill: #fff !important;
          }

          &:after {
            content: '';
            width: 100%;
            position: absolute;
            bottom: 0;
            background-image: var(--primary-gradient);
            height: 2px;
            z-index: 1;
            animation: grow 0.2s ease-in-out forwards;
            animation-direction: normal;
            animation-delay: 0.1s;
            transform-origin: 50%;
            opacity: 0;
          }
          @keyframes grow {
            from {
              transform: scaleX(0);
              opacity: 0;
            }
            to {
              transform: scaleX(1);
              opacity: 1;
            }
          }
        }
      }
    }
    .panels {
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;

      overflow: hidden;
      :global(.react-tabs__tab-panel--selected) {
        flex: 1;
        display: flex;
        flex-direction: column;
      }
      .tabPanel {

        overflow: hidden;


      }
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    position: relative;

    .pageSpinner {
      margin: var(--spacing-base) auto;
    }

    .endText {
      color: var(--text-muted);
      margin: var(--spacing-4xl) auto;
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateX(0px);
}

.beforeClose {
  transform: translateX(250px);
}
@media (max-width: #{$breakpoint-tablet}) {
  .drawer {
    height: 100%;
    margin-right: 0;
    border-radius: 0;
  }
}
