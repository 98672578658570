@import 'styles/colors.module.scss';

.menuDrawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(to right, transparent, transparent, rgba(0,0,0,1));
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: var(--z-index-modal);
  opacity: 0;
  transition: opacity 200ms ease-out;
}

.menuDrawer {
  height: calc(100% - 40px);
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  background-color: var(--grey-darkest);
  overflow: hidden;
  outline: none;
  transform: translateX(250px);
  transition: transform 200ms ease-in-out;
  margin-right: 20px;;

  .header {
    display: flex;
    align-items: center;
    background-color: var(--grey-darker70);
    position: relative;
    height: 60px;
    padding: 0 var(--spacing-base);

    .closeIcon {
      padding: var(--spacing-2sm);
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  .content {
    flex-grow: 1;
  }

  .footer {
    height: 60px;
    margin-top: auto;

    .logoutItem {
      &:hover {
        background-color: var(--secondary);
      }
      .logoutIcon {
        padding-left: 5px;
      }
    }
  }
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.afterOpen {
  transform: translateX(0px);
}

.beforeClose {
  transform: translateX(250px);
}
