$colors: (
  'primary': #ff6200,
  'secondary': #a200fa,
  'tertiary': #C82EA6,
  'text': #9797B4,
  'text-muted': #6B6B94,
  'white': #ffffff,
  'grey': #181826,
  'grey-mid': #202032,
  'grey-dark': #1A1A28,
  'grey-darker': #0E0E16,
  'grey-darkest': #08080C,
  'gold': #fbad29,
  'silver': #bdbac0,
  'bronze': #7b3717,
  'common': #ffb272,
  'uncommon': #66bc66,
  'rare': #109aee,
  'epic': #bd1288,
  'legendary': #ffaa22,
  'mythic': #26e5bf,
  'exotic': #ff2012,
  'immutable': #17B5CB,
  'eth': #FF7744,
);


$primary: map-get($colors, 'primary');
$primary-dark: scale-color($primary, $saturation: 20%, $lightness: -10%);
$primary-light: scale-color($primary, $saturation: 40%, $lightness: 20%);

$secondary: map-get($colors, 'secondary');
$secondary-dark: scale-color($secondary, $saturation: 25%, $lightness: -25%);
$secondary-light: scale-color($secondary, $saturation: 25%, $lightness: 25%);

$tertiary: map-get($colors, 'tertiary');
$tertiary-dark: scale-color($tertiary, $saturation: 25%, $lightness: -25%);
$tertiary-light: scale-color($tertiary, $saturation: 25%, $lightness: 25%);

$text: map-get($colors, 'text');
$text-muted: map-get($colors, 'text-muted');
$white: map-get($colors, 'white');

$grey: map-get($colors, 'grey');
$grey-mid: map-get($colors, 'grey-mid');
$grey-dark: map-get($colors, 'grey-dark');
$grey-darker: map-get($colors, 'grey-darker');
$grey-darkest: map-get($colors, 'grey-darkest');
$transparent: rgba($grey-darkest, 0);

$gold: map-get($colors, 'gold');
$gold-dark: scale-color($gold, $lightness: -25%);
$silver: map-get($colors, 'silver');
$silver-dark: scale-color($silver, $lightness: -25%);
$bronze: map-get($colors, 'bronze');
$bronze-dark: scale-color($bronze, $lightness: -25%);

$common: map-get($colors, 'common');
$uncommon: map-get($colors, 'uncommon');
$rare: map-get($colors, 'rare');
$epic: map-get($colors, 'epic');
$legendary: map-get($colors, 'legendary');
$mythic: map-get($colors, 'mythic');
$exotic: map-get($colors, 'exotic');
$immutable: map-get($colors, 'immutable');
$eth: map-get($colors, 'eth');

:export {
  primary: $primary;
  primaryDark: $primary-dark;
  primaryLight: $primary-light;
  secondary: $secondary;
  tertiary: $tertiary;
  text: $text;
  textMuted: $text-muted;
  white: $white;
  grey: $grey;
  greyMid: $grey-mid;
  greyDark: $grey-dark;
  greyDarker: $grey-darker;

  greyDarkest: $grey-darkest;
  gold: $gold;
  silver: $silver;
  bronze: $bronze;
}
