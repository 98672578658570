@import 'styles/breakpoints.module.scss';

.headerNavItems {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
}

.navDropdown {
  &:hover {
    .navItemLabel {
      opacity: 1;
      visibility: visible;
    }

    .inner {
      opacity: 1;
      visibility: visible;
    }
  }
  .inner {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 60px;
    left: 0;
    padding: var(--spacing-lg);
    width: 400px;

    transition: all 0.25s ease-in-out;
  }
}

.signedOutHeaderNavItems {
  margin: 0;
  display: none;
  flex-direction: column;
  background: var(--grey-dark);
  position: absolute;
  top: 70px;
  right: var(--spacing-base);
  align-items: flex-end;
  min-width: 15ch;
  padding: 0 var(--spacing-xl);

  &.isMobileMenuVisible {
    display: flex;
    z-index: 5;
  }

  .navItem {
    margin: 0;
    text-align: center;
    padding: 0 var(--spacing-2xs);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: $breakpoint-laptop) {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: static;
    background: none;
    margin: 0 4px;

    .navItem {
      margin: 0 4px;
      padding: 0 var(--spacing-2sm);
    }
  }
}
.discordNavItem {
  margin-left: var(--spacing-lg) !important;
  position: relative;
  &::before {
    content: '';
    background-color: var(--grey-dark);
    height: 30px;
    width: 1px;
    position: absolute;
    left: -15px;
  }
}


.navItem,
.navDropdown {
  height: 60px;
  padding: 0 var(--spacing-sm);
  display: flex;
  align-items: center;
  align-content: center;
  transition: var(--transition-short-ease);
  position: relative;
  text-align: center;

  .discordIcon {
    fill: var(--text) !important;
    color: var(--text) !important;
  }

  svg {
    transition: all 0.2s ease-in-out;
  }


  &.navItemActive {
    &::after {
      content: '';
      height: 100%;
      background-image: var(--primary-gradient);
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      animation: grow 0.4s cubic-bezier(1, 0, 0.53, 1) forwards;
      animation-direction: normal;
      animation-delay: 0.2s;
      transform-origin: 100% 0%;
      opacity: 0;
      z-index: -1;
    }
    &::before {
      content: '';
      height: 100%;
      background-color: var(--grey-dark);
      position: absolute;
      bottom: 0px;
      left: 0;
      right: 0;
      animation: grow 0.8s ease-in-out forwards;
      animation-direction: reverse;
      transform-origin: 100% 100%;
      opacity: 1;
      z-index: -1;
    }
    @keyframes grow {
      from {
        transform: scaleY(0);
        opacity: 0;
      }
      to {
        transform: scaleY(1);
        opacity: 1;
      }
    }
  }

  .navItemLabel {
    margin-left: var(--spacing-2xs);
    transition: var(--transition-short-ease);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 50px;
    background: black;
    border-radius: 4px;
    padding: var(--spacing-xs);
    white-space: nowrap;
    z-index: 1;
    transform: translateY(-5px);
    max-width: none !important;
    width: auto !important;
    &:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      transform: rotate(45deg);
      position: absolute;
      top: -3px;
      left: 6px;
      background: black;
    }
  }
  &:hover {
    opacity: 1;
    color: #fff;
    filter: none;

    svg {
      fill: white !important;
    }
  }
  &:hover:not(.navItemActive) {
    background-color: var(--grey-dark);
    .navItemLabel {
      color: var(--text);
      opacity: 1;
      transform: translateY(0px);
      visibility: visible;
    }
  }
}

@media (max-width: #{$breakpoint-tablet}) {
  .socialHeaderNavItems {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .headerNavItems {
    background: var(--grey-darkest);
    justify-content: space-evenly;
    flex-direction: row;
    border-top: solid 1px var(--grey-dark);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 4px;
    padding-bottom: 26px;
    .navItem {
      //width: 44px;
      height: 50px;
      //padding: 0 var(--spacing-lg);
      border-radius: var(--border-radius-small);
      overflow: hidden;
      &::after, &::before {
        border-radius: var(--border-radius-small);
      }
      .navItemLabel {
        display: none;
        &:after {
          display: none;
        }
      }
    }
  }
  .mobileNavItems {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row !important;
    flex-wrap: nowrap;
    .navItem {
      width: 60px;
      padding: 0 var(--spacing-lg);
      display: inline-block;

    }
  }
}
