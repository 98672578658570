.alertsDrawerItem {
  --icon-size: 50px;
  border-bottom: 1px solid var(--grey-dark);


  .baseAlertItem {
    display: flex;
    align-items: flex-start;
    padding: var(--spacing-sm) var(--spacing-sm) var(--spacing-sm) 0;
    position: relative;

    .success {
      position: absolute;
      top: var(--spacing-sm);
      right: var(--spacing-base);
      border: solid 2px green;
      padding: 0px var(--spacing-xs);
      height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius-full);
      color: green;
    }
    .fail {
      position: absolute;
      top: var(--spacing-sm);
      right: var(--spacing-base);
      border: solid 2px red;
      padding: 0px var(--spacing-xs);
      height: 22px;

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius-full);
      color: red;
    }

    .left {
      padding: 0 var(--spacing-base) 0 var(--spacing-lg);
      .icon {
        width: var(--icon-size);
        height: var(--icon-size);
        background: var(--grey-darker);
        border-radius: 50%;
        display: grid;
        place-items: center;
      }
    }

    .right {
      & > * + * {
        //margin-top: var(--spacing-2xs);
      }

      .created {
        color: var(--text-muted);
        margin-bottom: var(--spacing-2xs);
      }
      .title {
      }
      .body {
        line-height: 1.1em;
      }
      .message {
        color: var(--text-muted);
      }
    }

    &.unseen {
      position: relative;
      border-bottom: 1px solid var(--grey-dark);
      background-image: var(--block-gradient-two);
      .icon {
        background: rgba(255,255,255,0.05);
        &::before {
          position: absolute;
          left: 10px;
          top: calc(50% - 8px);
          background-image: var(--primary-gradient);
          width: 12px;
          height: 12px;
          content: '';
          border-radius: 20px;
        }
      }
    }
  }
}
